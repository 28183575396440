@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "TT Firs Neue ExtraLight";
    src: url("../fonts/TTFirsNeue-ExtraLight.woff");
}

@font-face {
    font-family: "TT Firs Neue Light";
    src: url("../fonts/TTFirsNeue-Light.woff");
}

@font-face {
    font-family: "TT Firs Neue Regular";
    src: url("../fonts/TTFirsNeue-Regular.woff");
}

@font-face {
    font-family: "TT Firs Neue DemiBold";
    src: url("../fonts/TTFirsNeue-DemiBold.woff");
}

@font-face {
    font-family: "TT Firs Neue Bold";
    src: url("../fonts/TTFirsNeue-Bold.woff");
}

@font-face {
    font-family: "TT Firs Neue Black";
    src: url("../fonts/TTFirsNeue-Black.woff");
}

@font-face {
    font-family: "TT Firs Neue Thin";
    src: url("../fonts/TTFirsNeue-Thin.woff");
}

    p{
        @apply mb-4 font-ttfirsneueregular ;
    }


    h1{
        @apply text-8xl font-ttfirsneuethin mb-4  ;
    }

    h2{
        @apply text-2xl font-ttfirsneuebold mb-4 uppercase ;
    }

    span{
        &.chapo{
            @apply text-lg font-ttfirsneuebold  ;
        }
    }